import type { IPublicData, IWixStyleParams } from '@wix/tpa-settings';
import type { IControllerConfig } from '@wix/yoshi-flow-editor';

const migrateStylesBooleansToSettingsConfig = [
  'showPostCount',
  'showAllPostsCategory',
  {
    param: 'showAllPostsCategoryMobile',
    mobileParam: 'showAllPostsCategory▶︎m',
    dependency: 'category-menu-mobile-isDisplaySettingsEnabled',
  },
  {
    param: 'showPostCountMobile',
    mobileParam: 'showPostCount▶︎m',
    dependency: 'category-menu-mobile-isDisplaySettingsEnabled',
  },
];

export const migrateStyleParamsToSettings = (
  publicData: IPublicData,
  styleParams: IWixStyleParams,
): IControllerConfig['publicData'] => {
  const { booleans } = styleParams;

  const component = publicData.COMPONENT || {};

  for (const key of migrateStylesBooleansToSettingsConfig) {
    if (typeof key === 'string' && typeof booleans[key] === 'boolean') {
      component[key] = booleans[key];
    }

    if (typeof key === 'object' && booleans[key.dependency] === true) {
      component[key.mobileParam] = booleans[key.param];
    }
  }

  return {
    APP: { ...publicData.APP, settingsMigrated: true },
    COMPONENT: component,
  };
};
